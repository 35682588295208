export const alertTypes = [
  {
    value: "price",
    label: "price",
  },
  {
    value: "insider",
    label: "insider",
  },
  {
    value: "news",
    label: "news",
  },
  {
    value: "ai rating",
    label: "ai rating",
  },
];
export const conditions = [
  {
    value: "<",
    label: "<",
  },
  {
    value: "<=",
    label: "<=",
  },
  {
    value: ">",
    label: ">",
  },
  {
    value: ">=",
    label: ">=",
  },
];

export const channels = [
  {
    value: "1",
    label: "Web",
  },
  {
    value: "2",
    label: "Email",
  },
  {
    value: "3",
    label: "Both",
  },
];

export const dummyAlertSettings = [
  {
    id: 1,
    ticker: "DUMMY",
    alertType: "price",
    condition: ">",
    value: 100,
    channel: 3,
  },
  {
    id: 2,
    ticker: "DUMMY",
    alertType: "insider",
    condition: ">",
    value: 100,
    channel: 1,
  },
  {
    id: 3,
    ticker: "DUMMY",
    alertType: "filings",
    channel: 2,
  },
  {
    id: 4,
    ticker: "DUMMY",
    alertType: "news",
    channel: 3,
  },
  {
    id: 5,
    ticker: "DUMMY",
    alertType: "ai rating",
    channel: 1,
  },
  {
    id: 6,
    ticker: "DUMMY",
    alertType: "transcript",
    channel: 3,
  },
];
